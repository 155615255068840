

























































































































































































































































































































































































































































































































































































.Basicinformation {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
    padding: 10px 0;
  }
  .information {
    display: flex;
    flex-wrap: wrap;
    .information-item {
      width: 50%;
    }
  }
}
.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}
.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  span {
    width: 10rem;
  }
}
.custom-tree-node {
  display: flex;
  align-items: center;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40rem;
  font-size: 14px;
}
.ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40rem;
  font-size: 14px;
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-tree-node {
  .el-form-item {
    margin-bottom: 0px;
  }
}
.qualificationImageId {
  .el-image {
    display: flex;
    height: 500px;
  }
}
.dataqualificationImageId {
  margin-left: -2px;
}
.imgUpload {
  display: flex;
  justify-content: center;
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}
//人力资源样式重置
/deep/::-webkit-scrollbar-thumb{
  background-color: #eee;
  border-radius: 0px;
}
/deep/::-webkit-scrollbar-track-piece {
  background: #fff;
  border-radius: 0px;
}
/deep/.el-pager li{
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .el-pager li{
  background-color: #fff;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #2878FF;
  border: 1px solid #2878FF;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev{
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
/deep/.el-pager li.active+li{
  border-left: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .btn-next.disabled, /deep/.el-pagination.is-background .btn-next:disabled, /deep/.el-pagination.is-background /deep/.btn-prev.disabled, /deep/.el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background-color: #f4f4f5;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination button{
  border: 1px solid #d9d9d9;
}
/deep/.el-button--text{
  //color: #2878FF;
  font-size: 14px!important;
}
/deep/.bgc-bv{
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
  border-radius: 0;
}
.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{

}
/deep/.el-table th.el-table__cell>.cell{
  font-size: 14px!important;
  font-weight: 400!important;
}
/deep/.el-dialog__header{
  background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__title{
  color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
  color: #333;
}
/deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
  border-color: #2878FF !important;
}
